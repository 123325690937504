import React from "react"
import ModalRoutingContext from "gatsby-singita-theme/src/context/ModalRoutingContext"
import { Container, Modal } from "@singita/components"
import { navigate } from "gatsby"
import Main from "gatsby-singita-theme/src/components/Main"

const ConditionalLayout = ({
  children,
  modalSize,
  modalTitle,
  modalSubtitle,
  bg,
  ...rest
}) => {
  return (
    <ModalRoutingContext.Consumer>
      {({ isModal, prevModalUrl, closeTo }) =>
        isModal ? (
          <Modal
            isOpen
            title={modalTitle}
            subtitle={modalSubtitle}
            size={modalSize}
            handleClose={() =>
              navigate(closeTo, {
                state: {
                  noScroll: true,
                  fromModal: true,
                  isModal: !!prevModalUrl,
                },
                replace: true,
              })
            }
            bg={bg}
            isPageModal={true}
          >
            {children(isModal)}
          </Modal>
        ) : (
          <Main bg={bg} {...rest}>
            <Container>{children(false)}</Container>
          </Main>
        )
      }
    </ModalRoutingContext.Consumer>
  )
}

export default ConditionalLayout
