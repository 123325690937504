import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useContext,
  useCallback,
} from "react"
import { graphql } from "gatsby"
import { Box } from "@singita/components"
import { useSeo } from "gatsby-singita-theme/src/hooks"
import HelmetWrapper, {
  getJsonLd,
} from "gatsby-singita-theme/src/components/Helmet"
import { TEMPLATE_TYPES } from "gatsby-singita-theme/src/common/constants"
import { LODGE_DATA_MAP_SUBPAGES } from "../common/constants"
import RichTextRenderer from "../components/RichTextRenderer"
import {
  Heading,
  Section,
  theme,
  FilterItem,
  Filter,
} from "@singita/components"
import ConditionalLayout from "../components/ConditionalLayout"
import Link from "../components/Link"
import RatesTable from "../components/RatesTable"
import { NavbarContext } from "gatsby-singita-theme/src/context/NavbarContext"
import { LodgeContext } from "gatsby-singita-theme/src/context/LodgeContext"

const LodgeInformationSection = ({
  index,
  lodge,
  lodgeInfo,
  setElementRef,
  isModal,
  tag,
  colorScheme,
}) => {
  const { id, headline, links, content } = lodgeInfo
  const ref = useRef()

  let renderCta = []
  if (links) {
    renderCta = links.map(link => (
      <Link
        sx={{ marginBottom: "24px" }}
        key={link.id}
        colorScheme={colorScheme}
        {...link}
      />
    ))
  }

  useLayoutEffect(() => {
    if (ref) {
      setElementRef(lodgeInfo.id, ref)
    }
  }, [lodgeInfo.id, setElementRef])

  let years = []
  if (tag === "rates") {
    years = lodge.units
      .reduce((acc, { rates }) => {
        if (rates) {
          rates.forEach(({ year }) => {
            if (year && !acc.includes(year)) {
              acc.push(year)
            }
          })
        }
        return acc
      }, [])
      .filter(year => year >= new Date().getFullYear())
      .sort()
  }

  const [yearFilter, setYearFilter] = useState(
    years.length > 0 ? years[0] : null
  )
  if (years.length > 1) {
    const filter = (
      <Filter isVertical={true}>
        {years.map(year => {
          return (
            <FilterItem
              isVertical={true}
              active={yearFilter === year}
              onClick={() => setYearFilter(year)}
            >
              {year}
            </FilterItem>
          )
        })}
      </Filter>
    )
    renderCta.push(filter)
  }

  return (
    <Box
      ref={ref}
      id={`lodge-information-${id}`}
      data-testid={`lodge-information-${index}`}
    >
      <Section
        direction="row"
        headline={headline}
        textContainerSx={{ pr: [0, 5], top: isModal ? "120px" : "150px" }}
        renderCta={renderCta}
      >
        {tag === "rates" && (
          <Box mb={[10]}>
            <RatesTable
              lodge={lodge}
              yearFilter={yearFilter}
              colorScheme={colorScheme}
            />
          </Box>
        )}
        <RichTextRenderer content={content} colorScheme={colorScheme} />
      </Section>
    </Box>
  )
}

const LodgeInformationTemplate = props => {
  const {
    data: { lodge },
    location: { state },
  } = props
  const { isModal, anchor } = state || {}
  const lodgeInformation = lodge.lodgeInformation || []
  const [activeId, setActiveId] = useState(
    anchor || lodgeInformation.length ? lodgeInformation[0].id : null
  )
  const elementRefs = useRef({})
  const filtersRef = useRef()
  const { navbarHeight, setNavbarStyles } = useContext(NavbarContext)
  const { setLodgeData } = useContext(LodgeContext)

  const tags = !!lodge.tags ? JSON.parse(lodge.tags.internal.content) : []

  useLayoutEffect(() => {
    const container = isModal
      ? document.getElementById("modal-scrollable-content")
      : window
    const scrollCallback = () => {
      Object.keys(elementRefs.current).forEach(id => {
        const ref = elementRefs.current[id]
        const { top } = ref.current.getBoundingClientRect()
        const { offsetHeight } = ref.current
        if (container) {
          let scrollTop
          if (isModal) {
            scrollTop = container.scrollTop
          } else {
            scrollTop = window.pageYOffset
          }
          const offsetTop = Math.floor(top + scrollTop) - 150
          if (scrollTop >= offsetTop && scrollTop < offsetTop + offsetHeight) {
            setActiveId(id)
          }
        }
      })
    }

    if (container) {
      container.addEventListener("scroll", scrollCallback)
      return () => container.removeEventListener("scroll", scrollCallback)
    }
  }, [isModal])

  const scrollElementIntoView = useCallback(
    elemId => {
      const elem = document.getElementById(elemId)
      if (elem) {
        const container = isModal
          ? document.getElementById("modal-scrollable-content")
          : window
        const { offsetTop: top } = elem
        if (container) {
          if (!Boolean(container.scrollTo)) {
            container.scrollTop = top - 150
          } else {
            container.scrollTo({ behavior: "smooth", top: top - 150 })
          }
        }
      }
    },
    [isModal]
  )

  useEffect(() => {
    if (anchor) {
      scrollElementIntoView(`lodge-information-${anchor}`)
    }
  }, [anchor, scrollElementIntoView])

  useEffect(() => {
    if (!isModal) {
      setNavbarStyles({
        bg: theme.colors.brandBeige,
        logoColor: lodge.color,
        secondaryButtonColor: theme.colors.textDark,
      })
      setLodgeData(lodge.color)
      return () => {
        setNavbarStyles({})
        setLodgeData(null, null)
      }
    }
  }, [isModal, setNavbarStyles, setLodgeData, lodge.color])

  const onClick = id => {
    scrollElementIntoView(`lodge-information-${id}`)
  }
  const setElementRef = useCallback(
    (id, ref) => {
      elementRefs.current[id] = ref
    },
    [elementRefs]
  )

  const stickyTop = isModal ? "55px" : navbarHeight
  const seoProps = useSeo(lodge, LODGE_DATA_MAP_SUBPAGES)
  seoProps.title = `${seoProps.sdTitle} | Rates, Check-In Times & Information`
  seoProps.description = `All the information you need for your visit to the contemporary luxury of ${seoProps.sdTitle}`
  const jsonLd = getJsonLd({ ...seoProps }, TEMPLATE_TYPES.LODGE_INFO.title)

  const infoItems = lodgeInformation.filter(l => l.displayForLocale)

  return (
    <>
      <HelmetWrapper {...seoProps} schemaJsonLd={jsonLd} />
      <ConditionalLayout
        modalSize="large"
        modalTitle={`${lodge.fullTitle} Information`}
        bg={theme.colors.brandBeige}
        pageContext={props.pageContext}
      >
        {_ => (
          <Box pt={isModal ? [0] : [2, 6]}>
            <Box pt={isModal ? [7] : [10]}>
              <Box mb={[5]}>
                <Heading size="h1" as="h1" fontWeight="normal">
                  {`${lodge.fullTitle} Information`}
                </Heading>
                <Heading size="h2" fontWeight="normal"></Heading>
              </Box>
              <Box
                ref={filtersRef}
                sx={{ position: "sticky", top: stickyTop, left: 0, zIndex: 2 }}
                py={[1]}
                bg={theme.colors.brandBeige}
              >
                <Filter isVertical={false} color={lodge.color}>
                  {infoItems.map(({ id, headline }, index) => (
                    <FilterItem
                      id={`filter-item-${id}`}
                      data-testid={`filter-item-${index}`}
                      isVertical={false}
                      onClick={() => onClick(id)}
                      active={activeId === id}
                      activeColor={lodge.color}
                    >
                      {headline}
                    </FilterItem>
                  ))}
                </Filter>
              </Box>
              {infoItems.map((info, index) => {
                const tag = tags[info.contentful_id]
                return (
                  <LodgeInformationSection
                    index={index}
                    key={info.id}
                    tag={tag}
                    lodge={lodge}
                    lodgeInfo={info}
                    setElementRef={setElementRef}
                    isModal={isModal}
                    colorScheme={lodge.color}
                  />
                )
              })}
            </Box>
          </Box>
        )}
      </ConditionalLayout>
    </>
  )
}

export default LodgeInformationTemplate

export const LODGE_INFORMATION_QUERY = graphql`
  query lodgeInformation($slug: String!, $locale: String) {
    lodge: contentfulLodge(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      id
      fullTitle
      lodgeInformation {
        contentful_id
        id
        headline
        displayForLocale
        content {
          raw
          references {
            ...RichTextReferences
          }
        }
        links {
          __typename
          ...LinkFields
        }
      }
      color
      tags {
        internal {
          content
        }
      }
      region {
        headline
        country
        seasonDates {
          year
          season
          dates
        }
      }
      structuredData {
        headline
        image {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      ...UnitFields
    }
  }
`
